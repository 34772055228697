import React, { useState } from 'react';
import flyImage from 'src/images/BLK2FLY_LOGO.png';
import goImage from 'src/images/BLK2GO_Logo.png';
import { useGetVersions } from 'src/Utils/Hooks/Versions/useGetVersions';
import VersionInfo from './VersionInfo';
import './Landing.css';
const Landing: React.FC = () => {
  const [version, setVersion] = useState<boolean>(false);
  const { versionResponse } = useGetVersions();
  return (
    <>
      {!version ? (
        <div
          className="row"
          style={{ height: '100vh', marginLeft: '10vw', color: 'white' }}
        >
          <div className="col-lg-6">
            <div
              className="row"
              style={{ height: '40vh', alignContent: 'center' }}
            >
              <figure>
                <img
                  src={flyImage}
                  style={{ height: '30vh', width: '60vh', marginLeft: '2vw' }}
                  alt="BLK"
                />
                <figcaption style={{ paddingLeft: '16vw' }}>BLK2FLY</figcaption>
              </figure>
            </div>
            <div className="row" style={{ height: '50vh' }}>
              <figure>
                <img
                  src={goImage}
                  style={{ height: '40vh', width: '40vh', marginLeft: '7.5vw' }}
                  alt="BLK"
                />
                <figcaption style={{ paddingLeft: '16vw' }}>BLK2GO</figcaption>
              </figure>
            </div>
          </div>
          <div
            className="col-lg-6 mt-3"
            style={{
              height: '100vh',
              paddingTop: '10vh',
            }}
          >
            <h4>BLK SUPPORT PORTAL supports following devices:</h4>
            <ul>
              <li>BLK2FLY</li>
              <li>BLK2GO</li>
            </ul>
            <br />
            <br />
            <h4>It supports the ability to provide following features:</h4>
            <ul>
              <li>Display device twin information</li>
              <li>Provide access to the App and Device logs</li>
              <li>Show telemetry from Observer for specific device</li>
            </ul>
            <br />
            <br />
            Support Portal Version :
            <span
              className="clickableIcon"
              data-testid="version"
              onClick={() => setVersion(true)}
            >
              {'      '}V{versionResponse[0]?.Version}
            </span>
            <br />
            <br />
            <h5>Contact us : blk-iot-support.in.hxgn@hexagon.com</h5>
          </div>
        </div>
      ) : (
        <VersionInfo
          versionData={versionResponse}
          onBack={() => setVersion(false)}
        />
      )}
    </>
  );
};

export default Landing;
